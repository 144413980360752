import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'theme-ui';
import { StyledHeroWithMediaProps } from './types';

export const StyledHeroWithMediaContent = styled(Box)<StyledHeroWithMediaProps>`
  ${({ isContentNarrow }) =>
    isContentNarrow &&
    css`
      max-width: 38.75rem;
      width: 100%;
      margin: 0 auto;
      .react-autosuggest__container {
        width: 100%;
      }
    `};
`;

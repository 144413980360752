/** @jsxImportSource theme-ui */
import React from 'react';
import { Grid, Text, Box } from 'theme-ui';

import Section from 'components/styles/Section';
import useTheme from 'hooks/useTheme';
import { HeroBaseProps, HeroDivisionMode } from './types';

const HeroBase: React.FC<HeroBaseProps> = ({
  children,
  actions,
  title,
  titleTag = 'h1',
  description,
  divisionMode = HeroDivisionMode.Default,
  isCentered = false,
  ...props
}) => {
  const { modes } = useTheme().hero;

  return (
    <Section {...props}>
      <Grid variant="hero.grid" sx={{ ...modes[divisionMode] }}>
        <Box
          sx={{
            variant:
              children && !isCentered ? 'hero.wrapper' : 'hero.wrapperAlt',
          }}
          className="wrapper"
        >
          {title && (
            <Text as={titleTag} variant="hero.title">
              {title}
            </Text>
          )}
          {description && (
            <Text as="p" variant="hero.description">
              {description}
            </Text>
          )}
          {actions && <Box variant="hero.actions">{actions}</Box>}
        </Box>
        {children}
      </Grid>
    </Section>
  );
};

export default HeroBase;

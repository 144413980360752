import { useMemo } from 'react';

const DEFAULT_VIDEO_PLACEHOLDER_URL = '/assets/images/placeholder.png';

export const useVideoPlaceholderUrl = (
  src: string,
  placeholder: string | undefined,
): string =>
  useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    const parsedVideoUrl = new URL(src);

    if (!parsedVideoUrl.hostname.includes('youtube.com')) {
      return DEFAULT_VIDEO_PLACEHOLDER_URL;
    }

    const videoId = parsedVideoUrl.pathname.replace('/embed/', '');

    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }, [placeholder, src]);

/** @jsxImportSource theme-ui */
import React, { Fragment } from 'react';
import { Embed as ThemedEmbed } from 'theme-ui';

import useToggle from 'hooks/useToggle';
import { EmbedVideoProps } from './types';
import VideoPlaceholder from '../VideoPlaceholder';
import { useVideoPlaceholderUrl } from './hooks';

const Embed: React.FC<EmbedVideoProps> = ({
  src,
  ratio,
  placeholderSrc,
  alt,
  width,
  height,
  placeholderLoading,
  placeholderPriority,
}) => {
  const [isVideoVisible, { toggle }] = useToggle();
  const placeholder = useVideoPlaceholderUrl(src, placeholderSrc);

  return (
    <Fragment>
      {!isVideoVisible ? (
        <VideoPlaceholder
          src={placeholder}
          onClick={toggle}
          loading={placeholderLoading}
          priority={placeholderPriority}
          {...{ alt, width, height }}
        />
      ) : (
        <ThemedEmbed
          {...{ ratio }}
          allow="autoplay; encrypted-media"
          src={`${src}?autoplay=1`}
        />
      )}
    </Fragment>
  );
};

export default Embed;

import React from 'react';
import { Box, Button } from 'theme-ui';

import Image from 'components/atoms/Image';
import { IconPlay } from 'components/styles/Icons';
import { VideoPlaceholderProps } from './types';

const VideoPlaceholder: React.FC<VideoPlaceholderProps> = ({
  onClick,
  src,
  width,
  height,
  loading,
  priority = true,
}) => (
  <Box variant="video.placeholderImage">
    <Image
      {...{ src, width, height, loading, priority }}
      alt="Video placeholder"
    />
    <Button aria-label="Play video" variant="video.button" {...{ onClick }}>
      <IconPlay />
    </Button>
  </Box>
);

export default VideoPlaceholder;
